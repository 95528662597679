import React from "react"

export default function Grid() {
  return (
    <div class='grid grid-cols-6 grid-rows-6 gap-2 w-screen h-screen'>
      <div class='col-span-6 bg-gray-600'></div>
      <div class='bg-gray-600 hidden md:block row-span-4'></div>
      <div class='col-span-6 md:col-span-4 row-span-2 bg-green-600'></div>
      <div class='bg-gray-600 md:block row-span-4'></div>
      {/* <div class='col-span-6 md:col-span-4 md:col-start-2 bg-gray-600'></div> */}
    </div>
  )
}
