import React from "react"
import Layout from "./Layout"

import image from "./assets/Cluster-6_2000.jpg"
import switch_ from "./assets/Cluster-6_switch.gif"

const Cluster = ({ location }) => (
  <Layout location={location}>
    <svg
      className='cluster-board'
      viewBox='0 0 8000 5200'
      xmlns='http://www.w3.org/2000/svg'>
      <image href={image} x='400' y='125' width='7200'></image>

      <text textAnchor='end' className='cluster-image-title' y='100' dy='40'>
        <tspan x='1460'>Hinojosa Design Studio /</tspan>
        <tspan x='1460' dy='75'>
          Logo Design
        </tspan>
      </text>

      <text className='cluster-image-title' y='3400'>
        <tspan x='6200'>
          ANTIDOTE / Jewelry Collaboration Proposal for SOKO
        </tspan>
        <tspan x='6200' dy='75'>
          Design
        </tspan>
      </text>

      <text className='cluster-image-title' y='3970'>
        <tspan x='3320'>ANTIDOTE / The Switch Social Media Campaign</tspan>
        <tspan x='3320' dy='75'>
          Design
        </tspan>
      </text>

      <image href={switch_} x='1950' y='3000' width='1280'></image>
    </svg>
  </Layout>
)

export default Cluster
