import React, { useState, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import "./Layout.css"

function useKeyPress(targetKey) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false)

  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true)
      }
    }

    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false)
      }
    }
    window.addEventListener("keydown", downHandler)
    window.addEventListener("keyup", upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler)
      window.removeEventListener("keyup", upHandler)
    }
  }, [targetKey]) // Empty array ensures that effect is only run on mount and unmount

  return keyPressed
}

const NavLeft = ({ slug, ...rest }) => {
  const arrowLeft = useKeyPress("ArrowLeft")
  const linkRef = useRef()

  let cluster = slug.replace("/", "")
  let pathTo = cluster - 1 ? "/" + (cluster - 1) : "/"
  const to = {
    pathname: pathTo,
    state: {
      internal: true,
      transition: "left-to-cluster",
    },
  }
  useEffect(() => {
    if (arrowLeft) {
      linkRef.current.click()
    }
  }, [arrowLeft])
  return (
    <div className='md:ml-8' {...rest}>
      <Link ref={linkRef} to={to}>
        <button
          type='button'
          className='-mr-1 flex p-2 rounded-md transform scale-100 hover:scale-110 focus:outline-none focus:bg-white transition ease-in-out duration-200'
          aria-label='Left'>
          <svg
            className='h-10 w-8'
            fill='none'
            style={{ color: "#B1A493" }}
            viewBox='0 0 24 24'
            stroke='currentColor'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M15 19l-7-7 7-7'
            />
          </svg>
        </button>
      </Link>
    </div>
  )
}

const NavRight = ({ slug, ...rest }) => {
  const arrowRight = useKeyPress("ArrowRight")
  const linkRef = useRef()
  let cluster = slug.replace("/", "")
  let pathTo = cluster * 1 !== 6 ? "/" + (cluster * 1 + 1) : "/"
  const to = {
    pathname: pathTo,
    state: {
      internal: true,
      transition: "right-to-cluster",
    },
  }
  useEffect(() => {
    if (arrowRight) {
      linkRef.current.click()
    }
  }, [arrowRight])

  return (
    <div className='md:mr-8' {...rest}>
      <Link ref={linkRef} to={to}>
        <button
          type='button'
          className='-mr-1 flex p-2 rounded-md transform scale-100 hover:scale-110 focus:outline-none focus:bg-white transition ease-in-out duration-200'
          aria-label='Right'>
          <svg
            className='h-10 w-8'
            style={{ color: "#B1A493" }}
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M9 5l7 7-7 7'
            />
          </svg>
        </button>
      </Link>
    </div>
  )
}

function Layout({ children, location }) {
  return (
    <>
      <div className='hidden md:flex items-center row-span-4'>
        <NavLeft slug={location.pathname} />
      </div>
      <div
        className={`col-span-6 md:col-span-4 row-span-6 flex justify-center items-center ${location.state?.transition}`}
        style={{
          transformOrigin: location.state?.origin,
        }}>
        {children}
      </div>
      <div className='hidden md:flex items-center justify-end row-span-4'>
        <NavRight slug={location.pathname} />
      </div>
    </>
  )
}

export default Layout
