import React from "react"
import Layout from "./Layout"

import image from "./assets/Cluster-2_2000.jpg"

const Cluster = ({ location }) => (
  <Layout location={location}>
    <svg
      className='cluster-board'
      viewBox='0 0 8000 5200'
      xmlns='http://www.w3.org/2000/svg'>
      <image href={image} x='400' y='100' width='7200'></image>

      <text className='cluster-image-title' y='100'>
        <tspan x='6000'>Pinta Miami / Crossing Cultures Logo</tspan>
        <tspan x='6000' dy='75'>
          Design
        </tspan>
      </text>

      <text fill='white' className='cluster-image-title' y='3700'>
        <tspan textAnchor='end' x='6750'>
          ANTIDOTE / Je T'aime Miami Candle
        </tspan>
        <tspan textAnchor='end' x='6750' dy='75'>
          Design
        </tspan>
      </text>

      <text className='cluster-image-title' y='550'>
        <tspan x='975'>ANTIDOTE / Naadam Collaboration Proposal</tspan>
        <tspan x='975' dy='75'>
          Design
        </tspan>
      </text>

      <text className='cluster-image-title' y='4550'>
        <tspan x='1520'>ANTIDOTE / Upcycled Gingham Tote</tspan>
        <tspan x='1520' dy='75'>
          Design
        </tspan>
      </text>

      <text className='cluster-image-title' y='4170'>
        <tspan x='4400'>ANTIDOTE / Signature Logo Jacquard Scarf</tspan>
        <tspan x='4400' dy='75'>
          Design
        </tspan>
      </text>
    </svg>
  </Layout>
)

export default Cluster
