import React from "react"
import Layout from "./Layout"

import image from "./assets/Cluster-1_2000.jpg"

const Cluster = ({ location }) => (
  <Layout location={location}>
    <svg
      className='cluster-board'
      viewBox='0 0 8000 5200'
      xmlns='http://www.w3.org/2000/svg'>
      <image href={image} x='600' y='100' width='6800'></image>

      <text className='cluster-image-title' y='2300'>
        <tspan x='2600'>ANTIDOTE / Recycled Wrapping Paper </tspan>
        <tspan x='2600' dy='75'>
          Design
        </tspan>
      </text>

      <text className='cluster-image-title' y='260'>
        <tspan x='2675'>ANTIDOTE / Playing Card Set</tspan>
        <tspan x='2675' dy='75'>
          Design
        </tspan>
      </text>

      <text className='cluster-image-title' y='1000'>
        <tspan x='650'>ANTIDOTE / Je T'aime Miami Postcards</tspan>
        <tspan x='650' dy='75'>
          Design and Photography
        </tspan>
      </text>

      <text className='cluster-image-title' y='1000' dy='1000'>
        <tspan x='7000'>ANTIDOTE / Boy Smells Campaign</tspan>
        <tspan x='7000' dy='75'>
          Set Styling and Photography
        </tspan>
      </text>

      <text className='cluster-image-title' y='4000'>
        <tspan x='5600'>ANTIDOTE / Sustainable Paper Tape</tspan>
        <tspan x='5600' dy='75'>
          Design
        </tspan>
      </text>
    </svg>
  </Layout>
)

export default Cluster
