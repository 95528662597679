import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./Home.css"

import cluster1 from "./assets/Cluster-1_2000.jpg"
import cluster2 from "./assets/Cluster-2_2000.jpg"
import cluster3 from "./assets/Cluster-3_2000.jpg"
import cluster4 from "./assets/Cluster-4_2000.jpg"
import cluster5 from "./assets/Cluster-5_2000.jpg"
import cluster6 from "./assets/Cluster-6_2000.jpg"

const EMAIL = "elay@edgstudio.com"

function sendEmail(event) {
  window.open("mailto:" + EMAIL, "mail")
  event.preventDefault()
}

function Circle({ handleOrigin, ...props }) {
  const circleRef = React.useRef()
  const [isHover, setIsHover] = useState(false)
  function handleOnMouseEnter({ clientX: x, clientY: y }) {
    setIsHover(true)
    handleOrigin(x, y)
  }
  function handleOnMouseLeave() {
    setIsHover(false)
  }
  return (
    <circle
      ref={circleRef}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      className='Home-circle'
      style={{
        animationPlayState: isHover ? "paused" : "",
      }}
      {...props}
    />
  )
}
function Home() {
  const [origin, setOrigin] = useState("50% 50%")
  function handleOrigin(x, y) {
    setOrigin(
      `${(x / window.innerWidth) * 100}% ${(y / window.innerHeight) * 100}%`
    )
  }
  let state = {
    internal: true,
    origin: origin,
    transition: "scale-from-cluster",
  }

  return (
    <>
      <div className='hidden md:block row-span-4'></div>
      <div className='col-span-6 sm:col-span-4 row-span-2 md:row-span-4'>
        <svg
          className='Home-board'
          viewBox='0 0 200 110'
          xmlns='http://www.w3.org/2000/svg'>
          <Link
            to={{
              pathname: "/1",
              state,
            }}>
            <Circle
              id='circle1'
              fill='#2622c1'
              cx='20'
              cy='20'
              r='4'
              handleOrigin={handleOrigin}
            />
          </Link>
          <Link
            to={{
              pathname: "/2",
              state,
            }}>
            <Circle
              handleOrigin={handleOrigin}
              id='circle2'
              fill='#f2e9a3'
              cx='80'
              cy='40'
              r='12'
            />
          </Link>
          <Link
            to={{
              pathname: "/3",
              state,
            }}>
            <Circle
              handleOrigin={handleOrigin}
              id='circle3'
              fill='#415e3f'
              cx='100'
              cy='50'
              r='7'
            />
          </Link>
          <Link
            to={{
              pathname: "/4",
              state,
            }}>
            <Circle
              handleOrigin={handleOrigin}
              id='circle4'
              fill='#b7663b'
              cx='50'
              cy='60'
              r='8'
            />
          </Link>
          <Link
            to={{
              pathname: "/5",
              state,
            }}>
            <Circle
              handleOrigin={handleOrigin}
              id='circle5'
              fill='#d3bb95'
              cx='120'
              cy='80'
              r='10'
            />
          </Link>
          <Link
            to={{
              pathname: "/6",
              state,
            }}>
            <Circle
              handleOrigin={handleOrigin}
              id='circle6'
              fill='#b1bca9'
              cx='150'
              cy='45'
              r='4'
            />
          </Link>
          {/* eslint-disable-next-line */}
          <a>
            <Circle
              handleOrigin={handleOrigin}
              id='circle7'
              fill='#d3bdba'
              cx='175'
              cy='30'
              r='6'
            />
          </a>
        </svg>
      </div>
      <div className='hidden md:flex items-center row-span-4'>
        <div className='transform rotate-90'>
          <a
            className='opacity-100 hover:opacity-75'
            style={{ color: "#b1a493" }}
            onClick={sendEmail}
            href={"mailto:" + EMAIL}>
            {EMAIL}
          </a>
        </div>
      </div>
      <div className='col-span-6'>
        <div className='px-4 md:ml-8' style={{ color: "#b1a493" }}>
          <p className='italic md:leading-none'>
            To be modern in today’s creative landscape is to blur the lines and
            forgo preconceptions.
          </p>
          <p className='italic md:leading-none'>
            A lifelong string of affairs between different art forms sparks a
            desire for sublime visual experiences.
          </p>
          <p className='mt-4 text-sm'>
            ART DIRECTION | BRANDING | TEXTILE DESIGN | PRODUCT DESIGN |
            COPYWRITING
          </p>
        </div>
        <div className='md:hidden text-center mt-20'>
          <a
            className='opacity-100 hover:opacity-75'
            style={{ color: "#b1a493" }}
            onClick={sendEmail}
            href={"mailto:" + EMAIL}>
            {EMAIL}
          </a>
        </div>
        {/* Prefetch images of clusters */}
        <link rel='prefetch' href={cluster1} />
        <link rel='prefetch' href={cluster2} />
        <link rel='prefetch' href={cluster3} />
        <link rel='prefetch' href={cluster4} />
        <link rel='prefetch' href={cluster5} />
        <link rel='prefetch' href={cluster6} />
      </div>
    </>
  )
}

export default Home
