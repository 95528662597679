import React from "react"
import Layout from "./Layout"

import image from "./assets/Cluster-4_2000.jpg"

const Cluster = ({ location }) => (
  <Layout location={location}>
    <svg
      className='cluster-board'
      viewBox='0 0 8000 5200'
      xmlns='http://www.w3.org/2000/svg'>
      <image href={image} x='200' y='0' width='7600'></image>

      <text className='cluster-image-title' y='1525' dy='2200'>
        <tspan x='200'>ANTIDOTE / Recycled Gift Wrapping Paper</tspan>
        <tspan x='200' dy='75'>
          Design
        </tspan>
      </text>

      <text className='cluster-image-title' y='600'>
        <tspan x='2675'>ANTIDOTE / Brother Vellies Social Media Campaign</tspan>
        <tspan x='2675' dy='75'>
          Art Direction and Photography
        </tspan>
      </text>

      <text textAnchor='end' className='cluster-image-title' y='1300' dy='2750'>
        <tspan x='7350'>
          ANTIDOTE / Signature Logo Jacquard Scarf in 100% Alpaca
        </tspan>
        <tspan x='7350' dy='75'>
          Design
        </tspan>
      </text>

      <text className='cluster-image-title' y='300'>
        <tspan x='5650'>ANTIDOTE / Common Sense Socks</tspan>
        <tspan x='5650' dy='75'>
          Design
        </tspan>
      </text>

      <text className='cluster-image-title' y='4400'>
        <tspan x='2650'>ANTIDOTE / Biodegradable Travel Cup</tspan>
        <tspan x='2650' dy='75'>
          Design
        </tspan>
      </text>
    </svg>
  </Layout>
)

export default Cluster
