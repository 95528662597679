import React from "react"
import { Switch, Route, Link } from "react-router-dom"
import Home from "./Home"
import logo from "./assets/logo.svg"
import Grid from "./Grid"
import Cluster1 from "./Cluster1"
import Cluster2 from "./Cluster2"
import Cluster3 from "./Cluster3"
import Cluster4 from "./Cluster4"
import Cluster5 from "./Cluster5"
import Cluster6 from "./Cluster6"

function Logo() {
  return (
    <Link to='/' className='mt-4 md:mt-8 py-2 transform translate-x-0'>
      <figure className='h-14 w-6 md:w-8 md:h-16'>
        <img src={logo} className='h-full w-auto' alt='logo' />
      </figure>
    </Link>
  )
}
function App() {
  return (
    <div className='grid grid-cols-6 grid-rows-6 gap-2 w-screen h-screen'>
      <div className='col-span-6 flex justify-center'>
        <Logo />
      </div>
      <Switch>
        <Route exact path='/test' component={Grid} />
        <Route exact path='/' component={Home} />
        <Route exact path='/1' component={Cluster1} />
        <Route exact path='/2' component={Cluster2} />
        <Route exact path='/3' component={Cluster3} />
        <Route exact path='/4' component={Cluster4} />
        <Route exact path='/5' component={Cluster5} />
        <Route exact path='/6' component={Cluster6} />
      </Switch>
    </div>
  )
}
export default App
