import React from "react"
import Layout from "./Layout"

import image from "./assets/Cluster-5_2000.jpg"

const Cluster = ({ location }) => (
  <Layout location={location}>
    <svg
      className='cluster-board'
      viewBox='0 0 8000 5200'
      xmlns='http://www.w3.org/2000/svg'>
      <image href={image} x='600' y='0' width='6800'></image>

      <text className='cluster-image-title' y='4000'>
        <tspan x='830'>ANTIDOTE / Baseling 100% Organic Cotton Tee</tspan>
        <tspan x='830' dy='75'>
          Design and Copywriting
        </tspan>
        <tspan x='830' dy='75'>
          *Photography by Diego Armando / Arturo Alcala
        </tspan>
      </text>
    </svg>
  </Layout>
)

export default Cluster
